@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
:root {
  --primary-color: #1E88E5;
  --secondary-text-color: #475467;
  --primary-text-color: #101828;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}
*, ::after, ::before {
  box-sizing: border-box;
}