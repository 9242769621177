.input-container {
    line-height: 100%;
    font-size: 32px;
    margin-bottom: 15px;
}

label {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    display: block;
    padding: 0;
    /* margin-bottom: 0.25em; */
}

input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #EAECF0;
    margin-top: 0px;
    padding: 16px;
}

input::placeholder {
    font-weight: 500;
    color: #98A2B3;
    font-size: 14px;
    font-weight: 400;
}
/* For modern browsers that support the appearance property */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input-container .header {
    display: flex;
    align-items: baseline;
    gap: 1em;
}
.input-container .error-msg {
    color: red;
    font-size: .4em;
    line-height: 100%;
}
@media (max-width: 851px) {
    .input-container{
        font-size: 24px;
    }
}
@media (max-width: 500px) {
    .input-container{
        font-size: 12px;
    }
}
@media (max-width: 700px) {
    .input-container {
        line-height: 100%;
        font-size: 18px;
        margin-bottom: 1.666667em;
    }
    
    label {
        font-size: 1.166667em;
        margin-bottom: 0.66667em;
    }
    
    input {
        padding: 1.25em;
        font-size: 1em;
        font-weight: 600;
    }
    
    input::placeholder {
        font-size: 1em;
    }
    .input-container .error-msg {
        font-size: .9em;
    }
    
}