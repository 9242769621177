.form-wrapper {
    max-width: 40%;
    margin: auto;
    margin-top: 25vh;
    font-size: 32px;
}

.form-wrapper h3 {
    color: #101828;
    font-size: 0.67em;
    font-weight: 700;
}

.form-wrapper p {
    color: #475467;
    font-size: .5em;
    font-weight: 400;
}

.form-wrapper .tel {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 700;
}
.form-wrapper button:disabled {
    opacity: .4;
}
.form-wrapper button {
    width: 100%;
    background-color: var(--primary-color);
    border: none;
    color: white;
    padding: 14px;
    font-size: .5em;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 52px;
    margin-bottom: 48px;
}

@media (max-width: 851px) {
    .form-wrapper {
        font-size: 24px;
    }
}
@media (max-width: 500px) {
    .form-wrapper {
        font-size: 12px;
    }
}
@media (max-width: 700px) {
    .form-wrapper {
        max-width: 90%;
        margin: auto;
        margin-top: 18vh;
        font-size: 18px;
    }
    
.form-wrapper h3 {
    font-size: 1.33em;
}

.form-wrapper p {
    font-size: 1em;
    margin-bottom: 2.6667em;
}

.form-wrapper button {
    margin-top: 4.66667em;
    margin-bottom: 6.9166667em;
}
.form-wrapper button {
    padding: 1.16667em;
    font-size: 1em;
}
.form-container .footer-container{
    display: none;
}
}