.about-us-container {
    font-size: 32px;
}

.about-us-container .pre-header {
    color: #475467;
    font-size: .5em;
    font-weight: 400;
    margin-top: 9em;
    font-family: 'Inter';
    text-align: left;
    padding-left: 4em;
    margin-bottom: 20px;
}

.about-us-container h1 {
    font-size: 1.8em;
    margin: 0;
    padding-left: 1em;
}

.about-us-container h1 span {
    display: block;
}

.about-us-container h1 span:nth-child(1) {
    color: #475467;
    font-weight: 400;
}

.about-us-container h1 span:nth-child(2) {
    color: #101828;
    font-weight: 600;
}

.image-header-container {
    width: 100%;
    position: relative;
    display: flex;
    background-color: #0B3050;
    height: 68vh;
    margin-top: 48px;
}

.image-header-container .image-wrapper {
    width: 80%;
    height: 100%;
}

.image-header-container .image-wrapper img {
    width: 100%;
    height: 100%;
}

.image-header-container .history {
    position: absolute;
    background-color: white;
    height: 70%;
    width: 25%;
    right: 8%;
    padding: 2em 0.625em;
    top: 50%;
    transform: translateY(-50%);
}

.image-header-container .history .title {
    color: #000;
    font-size: .5em;
    font-weight: 600;
}

.image-header-container .history p {
    color: #475467;
    font-size: 0.4375em;
    font-weight: 400;
    line-height: 171.429%;
}

.about-us-container h2 {
    color: #101828;
    font-size: 1.25em;
    font-weight: 600;
    margin-top: 2.3125em;
    text-transform: capitalize;
    padding-left: 2em;
}
.about-us-description {
    display: flex;
    align-items: center;
    padding: 3em 10vw;
    gap: 10vw;
    background: #f6fafe;
}
.about-us-description article .title {
    color: #101828;
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 1em;
    padding: 0;
}
.about-us-description article .p {
    color: #475467;
    font-size: .5em;
    font-weight: 400;
    line-height: 200%;
}
.vision-and-misssion-container {
    display: flex;
    padding: 5vw;
    align-items: center;
    gap: 10vw;
    padding: 4em 5vw;
}
.vision-and-misssion-container .img-wrapper img {
    /* width: 100%; */
}
.vision-and-misssion-container .vision-and-misssion-cards {
    display: flex;
    position: relative;
    gap: 3.5vw;
}
.vision-and-misssion-container .vision-and-misssion-cards h2{
    position: absolute;
    top: -4.5626em;
    left: -1.9626em;
    color: #101828;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.25em;
}
.vision-and-misssion-container .vision-and-misssion-cards .card{
    border-radius: 5px;
    border: 1px solid #d0d5dd;
    padding: 0.75em 0.6875em 3.15em;
    max-width: 45%;
}
.vision-and-misssion-container .vision-and-misssion-cards .card .card-title {
    color: #101828;
    font-size: 0.75em;
    font-weight: 600;
    text-transform: capitalize;
}
.vision-and-misssion-container .vision-and-misssion-cards .card p {
    color: #475467;
    font-size: 0.43em;
    font-weight: 400;
    line-height: 171.429%;
}

/* media queries */

@media (max-width: 851px) {
    .about-us-container {
        font-size: 24px;
    }
    .about-us-container h1 {
        font-size: 2em;
    }
    .image-header-container {
        height: 18em;
        margin-top: 0.833em;
    }
    .image-header-container .history {
        height: fit-content;
        width: 37%;
        padding: 2em 0.833em;
    }
    .image-header-container .history .title {
        font-size: .833em;
    }
    
    .image-header-container .history p {
        font-size: 0.8333em;
        line-height: 180%;
    }
    
.about-us-description {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 2em 1.66667em;
    gap: 5.333em;
    background: transparent;
}
.about-us-description article .title {
    font-size: 1.333em;
    margin-bottom: 2.66667em;
    text-align: center;
}
.about-us-description article .p {
    font-size: 1.166667em;
    text-align: center;
}
.vision-and-misssion-container {
    flex-direction: column;
    padding: 1.6666667em;
    align-items: center;
    gap: 7.01em;
    margin-bottom: 9.9em;
}

.vision-and-misssion-container .img-wrapper img {
    width: 100%;
}
.vision-and-misssion-container .vision-and-misssion-cards {
    flex-direction: column;
    gap: 2em;
    align-items: center;
}
.vision-and-misssion-container .vision-and-misssion-cards h2{
    position: absolute;
    top: -4.5626em;
    left: 50%;
    transform: translateX(-50%);
    color: #101828;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.3333em;
}
.vision-and-misssion-container .vision-and-misssion-cards .card{
    padding: 2.666667em 1.3333em;
    max-width: 95%;
}
.vision-and-misssion-container .vision-and-misssion-cards .card .svg-wrapper {
    margin-bottom: 2em;
}
.vision-and-misssion-container .vision-and-misssion-cards .card .card-title {
    font-size: 1.33333em;
}
.vision-and-misssion-container .vision-and-misssion-cards .card p {
    font-size: 1em;
    line-height: 200%;
}
}

/* mobile view */
@media (max-width: 700px) {
    .about-us-container {
        font-size: 18px;
    }
    .about-us-container .pre-header {
        margin-top: 8em;
        font-size: 1em;
    }
}
@media (max-width: 500px) {
    .about-us-container {
        font-size: 12px;
    }
    .about-us-container .pre-header {
        margin-top: 8em;
        font-size: 1em;
    }
    .about-us-description .img-wrapper {
        width: 15.666em;
    }
    .about-us-description .img-wrapper img{
        width: 100%;
    }
    .vision-and-misssion-container .img-wrapper {
        width: 15.666em;
    }
}