.reviews-container {
    /* width: 100vw; */
    overflow-x: hidden;
    padding-bottom: 10vw;
}
@keyframes slidex {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
.reviews-slide {
    display: flex;
    white-space: nowrap;
    gap: 2.5vw;
    animation: 18s slidex infinite linear;
}
.reviews-slide:hover {
    animation-play-state: paused;
}

.review-card {
    border-radius: 5px;
    border: 1px solid #EAECF0;
    padding: .5em;
    min-width: 35vw;
    background: #fff;
    position: relative;
}
.review-card:hover {
    scale: 1.2;
    transition: 300ms ease-in-out;
    z-index: 99;
    position: relative;
}
.review-card .top {
    display: flex;
    gap: 0.375em;
}
.review-card .top span {
    font-size: .5em;
    font-weight: 700;
}
.review-card .middle {
    font-size: 0.4375em;
    font-weight: 400;
    color: #667085;
    margin: .5em 0;
    white-space: normal;
}
.review-card .bottom {
    display: flex;
    align-items: center;
    gap: 0.375em;
}
.review-card .bottom .reviewer-details .name {
    font-size: 0.4375em;
    font-weight: 600;
    color: #101828;
}
.review-card .bottom .reviewer-details .occupation {
    color: #667085;
    font-size: 0.4375em;
    font-weight: 400;
}

/* media queries */
@media (max-width: 545px) { 
.reviews-slide {
    display: block;
    white-space: nowrap;
    animation: none;
}

.review-card {
    border-radius: 5px;
    border: 1px solid #EAECF0;
    padding: 1.166667em 2em;
    width: 90%;
    margin: auto;
    margin-bottom: 1.666667em;
}
.review-card:hover {
    scale: 1;
    transition: none;
}
.review-card .top {
    display: flex;
    gap: 1em;
}
.review-card .top span {
    font-size: 1em;
    font-weight: 700;
}
.review-card .middle {
    font-size: 1em;
    margin: .9166667em 0;
    white-space: normal;
}
.review-card .bottom {
    gap: 1em;
}
.review-card .bottom .reviewer-details .name {
    font-size: 1em;
}
.review-card .bottom .reviewer-details .occupation {
    font-size: 1em;
}

}