.navbar-wrapper {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
.navbar-container {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
    align-items: center;
    padding: 32px 4.5vw;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #EAECF0;
}
.logo-wrapper {
    color: black;
    font-weight: 600;
    font-size: .5em;
}
.navbar-container .nav-items {
    display: flex;
    list-style: none;
    gap: 1em;
    margin: 0;
}
.navbar-container .nav-items li a {
    text-decoration: none;
    color: var(--primary-text-color);
    font-size: .5em;
    font-weight: 400;
    transition: 100ms;
}
.navbar-container .nav-items li a:hover,
.navbar-container .nav-items li a:focus,
.navbar-container .nav-items li a:active {
    color: var(--primary-color);
    font-weight: 700;
}
.navbar-container .nav-items .highlight a {
    color: var(--primary-color);
    font-weight: 700;
}
.nav-button {
    font-size: .5em;
}
.mobile-wrapper {
    display: none;
}

/* mobile view */
@media (max-width: 851px) {
    .navbar-container {
        font-size: 24px;
    }
    .logo-wrapper {
        width: 80px;
    }
    .logo-wrapper img {
        width: 100%;
    }
}
@media (max-width: 700px) {
    .mobile-wrapper {
        display: flex;
    }
    .desktop-wrapper {
        display: none;
    }
}